/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';
import PortalLayout from '../../../../layouts/PortalLayout';
import { Loader, Message, SectionBox } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { responseSelector, hasErrorSelector, isBusySelector, getAncestryThunk } from '../../../../store/ancestry';
import AncestryMap from './components/AncestryMap';
import Grid from '@mui/material/Grid';
import AncestryList from './components/AncestryList';
import AncestryGraph from './components/AncestryGraph';

function AncestryScene() {
  const { t } = useTranslation('portal');

  const dispatch = useAppDispatch();
  const isBusy = useAppSelector(isBusySelector);
  const hasError = useAppSelector(hasErrorSelector);
  const response = useAppSelector(responseSelector);

  useEffect(() => {
    if (!response) {
      dispatch(getAncestryThunk());
    }
  }, [dispatch, response]);

  return (
    <PortalLayout>
      <SectionBox>
        <Typography variant="h5" component="h1" paragraph textAlign="center">
          {t('ancestry.title')}
        </Typography>
        {hasError && (
          <Message severity="error" title={`${t('alerts.AncestryFailed.title')}`}>
            {t('alerts.AncestryFailed.text')}
          </Message>
        )}
        <Grid container>
          <Grid item xs={12} sm={6} pl={{ sm: 1 }} pr={{ md: 5 }}>
            <AncestryList ancestryData={response} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AncestryGraph ancestryData={response} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <AncestryMap ancestryData={response} />
          </Grid>
        </Grid>
      </SectionBox>
      <Loader isVisible={isBusy} label={t('loaders.waiting') as string} />
    </PortalLayout>
  );
}

export default AncestryScene;
