import Typography from '@mui/material/Typography';
import { SectionBox, Formatter, theme } from '@omnigenbiodata/ui';
import { EhrItem } from '../../../../../../core/api/portal.types';

interface SimpleListProps {
  data: EhrItem[];
  type: string;
}

const SimpleList = ({ data, type }: SimpleListProps) => (
  <>
    {data.map((item: any, immIndex: number) => (
      <SectionBox color="success" key={`${type}-${immIndex}`}>
        <Typography variant="body1" component="p" fontWeight="bold" color={theme.palette.primary.main}>
          {item.description}
        </Typography>
        <Typography variant="body2" component="p">
          <Formatter input={item.effectiveDate} format="friendlyDate" />
        </Typography>
      </SectionBox>
    ))}
  </>
);

export default SimpleList;
