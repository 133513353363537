export const getParticipantQuery = `
query getParticipantQuery {
    getParticipant {
        NHSNumber
        PISConfirm
        VideoConfirm
        address1
        address2
        town
        createdTs
        dob
        firstName
        genderIdentity
        genderIdentityOther
        landline
        mobile
        odsCode
        orderStatus
        postcode
        sexAtBirth
        surname
        updatedTs
        withdrawalLevel
        termsVersion
        privacyVersion
    },
}
`;

export const getParticipantPreferencesQuery = `
query getParticipantPreferencesQuery {
    getParticipantPreferences {
       ehrOptin
       ancestryOptin
       communicationOptin
    },
}
`;

export const getConsentPdfLinkQuery = `
query getConsentPdfLink {
    getConsentPdfLink
}
`;

export const getAddressesQuery = `
query getAddressesQuery($postcode: String!, $container: String) {
    getAddressesQuery(postcode: $postcode, container: $container) {
        id
        text
        description
        type
    }
}
`;

export const getAddressDetailsQuery = `
query getAddressDetailsQuery($id: String!) {
    getAddressDetailsQuery(id: $id) {
        address1
        address2
        postcode
        town
    }
}
`;

export const getEhrStatusQuery = `
query getEhrStatusQuery {
    getEhrStatus {
      dataHasArrived
      ehrStatus {
        status
        updatedTs
      }
  }
}
`;

export const getImmunisationsQuery = `
query getEhrImmunisationsQuery {
    getImmunisations {
        description
        effectiveDate
      }
}
`;

export const getConditionsQuery = `
query getConditionsQuery {
    getConditions {
        description
        effectiveDate
      }
}
`;

export const getAncestryQuery = `
query getAncestryQuery {
    getAncestry {
        status
        modelVersion
        regions {
            geneticRegion
            geneticRegionLabel
            groups {
                group
                label
                percentage
                locations {
                    lat
                    lng
                }
            }
        }
    }
}`;

export const getAllergiesQuery = `
query getAllergiesQuery {
    getAllergies {
        description
        effectiveDate
      }
}
`;

export const getMedicationsQuery = `
query getMedicationsQuery {
    getMedications {
        description
        effectiveDate
        directions
        durationInDays
        issueType
      }
}
`;
