/** @jsxImportSource @emotion/react */
import { useRef, useEffect } from 'react';
import { feature } from 'topojson';
import { select, json, geoNaturalEarth1, geoPath } from 'd3';
import { useStyles } from '../../component.styles';
import Box from '@mui/material/Box';
import { Ancestry, AncestryRegion } from '../../../../../../core/api/portal.types';

export interface AncestryMapProps {
  ancestryData: Ancestry | null;
}

interface AncestryLocation {
  lat: number;
  lng: number;
  group: string;
}

function AncestryMap({ ancestryData }: AncestryMapProps) {
  const ref = useRef(null as unknown as SVGSVGElement);
  const classes = useStyles();
  useEffect(() => {
    if (ancestryData && ancestryData.status === 'complete') {
      const projection = geoNaturalEarth1();
      const pathGenerator = geoPath().projection(projection);

      if (ref.current) {
        json('/world.json').then((data: any) => {
          const countries: any = feature(data, data.objects.countries);
          const svg = select<SVGSVGElement, null>(ref.current);
          const map = svg.append('g');

          function drawMap(selection: any) {
            selection
              .selectAll('path')
              .data(countries.features)
              .enter()
              .append('path')
              .attr('d', (d: any) => pathGenerator(d))
              .append('title')
              .text((d: any) => {
                return d.properties.name;
              });
          }

          /* draws world map */
          map.call(drawMap);

          const defs = map.append('defs');

          defs.append('filter').attr('id', 'blur').append('feGaussianBlur').attr('stdDeviation', '8');
          defs.append('clipPath').attr('id', 'mapMask').call(drawMap);

          /* adds ancestry regions */
          const matchedLocations = ancestryData.regions.reduce<AncestryLocation[]>((list, item: AncestryRegion) => {
            for (const group of item.groups) {
              for (const location of group.locations) {
                list.push({
                  ...location,
                  group: group.group,
                });
              }
            }
            return list;
          }, []);
          map
            .append('g')
            .selectAll('circle')
            .data(matchedLocations)
            .enter()
            .append('circle')
            .attr('class', (d: any) => d.group)
            .attr('cx', (d: any) => projection([d.lng, d.lat])![0])
            .attr('cy', (d: any) => projection([d.lng, d.lat])![1])
            .attr('r', 13)
            .style('filter', 'url(#blur)')
            .attr('clip-path', 'url(#mapMask)');
        });
      }
    }
  }, [ancestryData]);
  return (
    <Box css={classes.ancestry} data-testid="ancestry-map">
      <svg width="100%" height="100%" viewBox="50 -50 900 500" preserveAspectRatio="xMaxYMax meet" ref={ref} />
    </Box>
  );
}

export default AncestryMap;
