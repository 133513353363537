import { css } from '@emotion/react';
import { theme } from '@omnigenbiodata/ui';

export const useStyles = () => {
  return {
    ancestry: css`
      path,
      line {
        stroke: ${theme.palette.primary.lighter};
        fill: ${theme.palette.primary.lighter};
        stroke-width: 0.5px;
      }
      text {
        font-size: 0.5rem;
      }
      .percentage {
        stroke-width: 0.2px;
      }
      .hidden {
        fill: none;
        stroke: none;
      }
      .label {
        fill: white;
        font-weight: 600;
      }
      .afr {
        fill: #4fd968;
        stroke: #4fd968;
      }
      .afr_1 {
        fill: #008080;
        stroke: #008080;
      }
      .afr_2 {
        fill: #10ada3;
        stroke: #10ada3;
      }
      .afr_3 {
        fill: #a0d94f;
        stroke: #a0d94f;
      }
      .eur {
        fill: #d94b65;
        stroke: #d94b65;
      }
      .eur_3 {
        fill: #f3ad20;
        stroke: #f3ad20;
      }
      .eur_1 {
        fill: #d9d44f;
        stroke: #d9d44f;
      }
      .amr,
      .amr_1 {
        fill: #3a414a;
        stroke: #3a414a;
      }
      .eas_1 {
        fill: #b03386;
        stroke: #b03386;
      }
      .eas_2 {
        fill: #e68acf;
        stroke: #e68acf;
      }
      .eas {
        fill: #b565a7;
        stroke: #b565a7;
      }
      .csa {
        fill: #327bbf;
        stroke: #327bbf;
      }
      .csa_1 {
        fill: #4ba3d9;
        stroke: #4ba3d9;
      }
      .csa_2 {
        fill: #8d4bd9;
        stroke: #8d4bd9;
      }
      .oce {
        fill: #4fc7d9;
        stroke: #4fc7d9;
      }
      .mid {
        fill: #4d4bd9;
        stroke: #4d4bd9;
      }
    `,
  };
};
