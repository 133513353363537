/* Types  */
export interface GraphQlErrorLocation {
  line: number;
  column: number;
  sourceName: string | null;
}

export interface GraphQlError {
  path: string[];
  data: string | null;
  errorType: string;
  errorInfo: string | null;
  locations: GraphQlErrorLocation[];
  message: string;
}

export enum LoginMethods {
  EMAIL = 'EMAIL',
}

export enum OrderStatus {
  PENDING = 'PENDING',
  CREATED = 'CREATED',
  DISPATCHED = 'DISPATCHED',
  RECEIVED = 'RECEIVED',
  STORED = 'STORED',
}

export enum MedicationIssueType {
  REPEAT = 'REPEAT',
  ACUTE = 'ACUTE',
}

export interface EhrItem {
  effectiveDate: string;
  description: string;
}

export interface MedicationItem {
  effectiveDate: string;
  description: string;
  directions: string;
  durationInDays: number;
  issueType: MedicationIssueType;
}

export interface Coordinate {
  lat: number;
  lng: number;
}

export interface AncestryGroup {
  group: string;
  label: string;
  percentage: number;
  locations: Coordinate[];
}

export interface AncestryRegion {
  percentage?: number;
  geneticRegion: string;
  geneticRegionLabel: string;
  groups: AncestryGroup[];
}

export interface Ancestry {
  status: string;
  modelVersion: string;
  regions: AncestryRegion[];
}

export interface Participant {
  firstName: string;
  surname: string;
  dob: string;
  NHSNumber: string;
  mobile: string;
  landline: string;
  genderIdentity: string;
  genderIdentityOther: string;
  sexAtBirth: string;
  postcode: string;
  address1: string;
  address2?: string;
  town: string;
  odsCode: string;
  PISConfirm?: boolean;
  PISVersion?: string;
  PISDate?: string;
  VideoConfirm?: boolean;
  consent1?: boolean;
  consent2?: boolean;
  consent3?: boolean;
  consent4?: boolean;
  consent5?: boolean;
  consent6?: boolean;
  consent7?: boolean;
  consent8?: boolean;
  consent9?: boolean;
  updatedTs?: string;
  createdTs?: string;
  withdrawalTs?: string;
  consentFormVersion?: string;
  consentFormDate?: string;
  withdrawalLevel?: string;
  withdrawalReason?: string;
  termsVersion?: string;
  privacyVersion?: string;
  termsAccepted?: boolean;
  privacyAccepted?: boolean;
  sampleBarcode?: string;
  orderStatus?: OrderStatus;
}

export interface Preferences {
  ehrOptin: boolean;
  ancestryOptin: boolean;
  communicationOptin: boolean;
}

/* Payloads  */
export interface CreateParticipantInput {
  firstName: string;
  surname: string;
  dob: string;
  NHSNumber: string;
  mobile: string;
  landline: string;
  genderIdentity: string;
  genderIdentityOther: string;
  sexAtBirth: string;
  postcode: string;
  address1: string;
  address2?: string;
  town: string;
  ehrOptin: string;
  ancestryOptin: string;
}

export interface UpdateParticipantInput {
  mobile: string;
  landline: string;
  genderIdentity: string;
  genderIdentityOther: string;
  postcode: string;
  address1: string;
  address2?: string;
  town: string;
}

export interface AcceptLegalNoticesInput {
  termsAccepted: boolean;
  privacyAccepted: boolean;
  communicationOptin: boolean;
  termsVersion: string;
  privacyVersion: string;
}

export interface ConfirmSignupInput {
  loginMethod: string;
  loginParameter: string;
  odsCode?: string;
}

export interface UpdateParticipantPreferencesInput {
  ehrOptin: boolean;
  ancestryOptin: boolean;
  communicationOptin: boolean;
}

export interface WithdrawParticipantInput {
  withdrawalLevel: string;
  withdrawalReason: string;
}

/* Responses */
export type ConfirmSignupResponse = {
  data?: { confirmSignUp: string };
  errors?: GraphQlError[];
};

export type CreateParticipantResponse = {
  data?: { createParticipant: Participant };
  errors?: GraphQlError[];
};

export type GetParticipantResponse = {
  data?: { getParticipant: Participant };
  errors?: GraphQlError[];
};

export type UpdateParticipantResponse = {
  data?: { updateParticipant: Participant };
  errors?: GraphQlError[];
};

export type GetParticipantPreferencesResponse = {
  data?: { getParticipantPreferences: Participant };
  errors?: GraphQlError[];
};

export type UpdateParticipantPreferencesResponse = {
  data?: { updateParticipantPreferences: Participant };
  errors?: GraphQlError[];
};

export type WithdrawParticipantResponse = {
  data?: { withdrawParticipant: Participant };
  errors?: GraphQlError[];
};

export type GetConsentPdfLinkResponse = {
  data?: { getConsentPdfLink: string };
  errors?: GraphQlError[];
};

export type GetEhrStatusResponse = {
  data?: { getEhrStatus: string };
  errors?: GraphQlError[];
};

export type GetImmunisationsResponse = {
  data?: { getImmunisations: EhrItem[] };
  errors?: GraphQlError[];
};

export type GetConditionsResponse = {
  data?: { getConditions: EhrItem[] };
  errors?: GraphQlError[];
};

export type GetAncestryResponse = {
  data?: { getAncestry: Ancestry };
  errors?: GraphQlError[];
};

export type GetMedicationsResponse = {
  data?: { getMedications: MedicationItem[] };
  errors?: GraphQlError[];
};

export type GetAllergiesResponse = {
  data?: { getAllergies: EhrItem[] };
  errors?: GraphQlError[];
};

export type UpdateParticipantEmailResponse = {
  data?: { updateParticipantEmail: string };
  errors?: GraphQlError[];
};

export type AcceptLegalNoticesResponse = {
  data?: { acceptLegalNotices: string };
  errors?: GraphQlError[];
};
