/** @jsxImportSource @emotion/react */
import { useState, useEffect, useRef, MutableRefObject } from 'react';
import { select, arc, pie, PieArcDatum, DefaultArcObject } from 'd3';
import { Ancestry, AncestryGroup, AncestryRegion, Coordinate } from '../../../../../../core/api/portal.types';
import Box from '@mui/material/Box';
import { useStyles } from '../../component.styles';

export interface AncestryGraphProps {
  ancestryData: Ancestry | null;
}

type Data = {
  percentage: number;
  geneticRegion?: string;
  geneticRegionLabel?: string;
  groups?: AncestryGroup[];
  group?: string;
  label?: string;
  locations?: Coordinate[];
};

const drawPieChart = (data: Data[], index: number, ref: MutableRefObject<SVGSVGElement>) => {
  const svg = select<SVGSVGElement, null>(ref.current);
  const width = 30;

  const pieChart = pie<Data>()
    .sort(null)
    .value((d: Data) => d.percentage);

  const outerRadius = index === 1 ? 4 * width : 2 * width;
  const innerRadius = index === 1 ? 3 * width : 0;

  const path = arc<PieArcDatum<Data>>().outerRadius(outerRadius).innerRadius(innerRadius);

  /* draws pie chart */
  const g = svg
    .append('g')
    .style('stroke', 'none')
    .attr('class', 'pie')
    .selectAll('.arc')
    .data(pieChart(data))
    .enter()
    .append('g');
  g.append('path')
    .attr('d', path)
    .attr('class', (d: PieArcDatum<Data>) => d.data.geneticRegion || d.data.group!)
    .style('stroke', 'none');

  /* determines whether label is on the top or bottom half of the chart */
  const isTop = (d: PieArcDatum<Data>) => {
    const midpoint = (d.startAngle + d.endAngle) / 2;
    const result = midpoint < Math.PI / 2 || midpoint > Math.PI * 1.5 ? true : false;
    return result;
  };

  /* creates hidden arc for text to flow along */
  const hiddenPath = path
    .innerRadius(outerRadius)
    .startAngle((d: PieArcDatum<Data>) => {
      const midpoint = (d.startAngle + d.endAngle) / 2;
      return isTop(d) ? midpoint - 0.25 * Math.PI : midpoint + 0.25 * Math.PI;
    })
    .endAngle((d: PieArcDatum<Data>) => {
      const midpoint = (d.startAngle + d.endAngle) / 2;
      return isTop(d) ? midpoint + 0.25 * Math.PI : midpoint - 0.25 * Math.PI;
    });
  g.append('path')
    .attr('class', 'hidden')
    .attr('id', (d, i) => {
      return `donutArc_${index}_${i}`;
    })
    .attr('d', hiddenPath);

  /* sets position of percentage labels */
  const getPercentagePosition = (d: PieArcDatum<Data>) => {
    const newArc: DefaultArcObject = d as unknown as DefaultArcObject;
    if (index === 1) {
      newArc.outerRadius = (index + 4) * width;
      newArc.innerRadius = (index + 3) * width;
    } else {
      newArc.outerRadius = 2 * width;
      newArc.innerRadius = 3 * width;
    }
    return 'translate(' + arc<DefaultArcObject>().centroid(newArc) + ')';
  };

  /* draws percentage labels */
  g.append('text')
    .attr('class', (d: PieArcDatum<Data>) =>
      index === 0 && d.data.percentage < 0.02 ? 'hidden' : `percentage ${d.data.geneticRegion || d.data.group}`,
    )
    .attr('dy', 3)
    .attr('transform', getPercentagePosition)
    .attr('text-anchor', 'middle')
    .text((d: PieArcDatum<Data>) =>
      Number(d.data.percentage).toLocaleString(undefined, {
        style: 'percent',
        minimumFractionDigits: 1,
      }),
    );

  /* draws region labels */
  g.append('text')
    .attr('dy', (d) => (isTop(d) ? 12 : -6))
    .attr('class', 'label')
    .append('textPath')
    .attr('startOffset', '25%')
    .attr('text-anchor', 'middle')
    .attr('xlink:href', function (d: PieArcDatum<Data>, i: number) {
      if ((index === 1 && d.data.percentage > 0.065) || d.data.percentage > 0.2) return `#donutArc_${index}_${i}`;
      return '';
    })
    .text((d: PieArcDatum<Data>) => d.data.geneticRegionLabel || d.data.label!);

  /* get coordinates of lines to percentage positions */
  const getLinePosition = (d: DefaultArcObject) => {
    d.innerRadius = index === 1 ? 4 * width : 2 * width;
    d.outerRadius = index === 1 ? 4 * width : 2 * width;
    const [x1, y1] = arc<DefaultArcObject>().centroid(d);
    d.innerRadius = index === 1 ? 4.2 * width : 2.2 * width;
    d.outerRadius = index === 1 ? 4.2 * width : 2.2 * width;
    const [x2, y2] = arc<DefaultArcObject>().centroid(d);
    return {
      x1,
      x2,
      y1,
      y2,
    };
  };

  /* draws lines to percentage labels */
  g.append('line')
    .attr('class', (d: PieArcDatum<Data>) =>
      index === 0 && d.data.percentage < 0.02 ? 'hidden' : d.data.geneticRegion || d.data.group!,
    )
    .attr('x1', (d: PieArcDatum<Data>) => getLinePosition(d as unknown as DefaultArcObject).x1)
    .attr('x2', (d: PieArcDatum<Data>) => getLinePosition(d as unknown as DefaultArcObject).x2)
    .attr('y1', (d: PieArcDatum<Data>) => getLinePosition(d as unknown as DefaultArcObject).y1)
    .attr('y2', (d: PieArcDatum<Data>) => getLinePosition(d as unknown as DefaultArcObject).y2);
};

function AncestryGraph({ ancestryData }: AncestryGraphProps) {
  const classes = useStyles();
  const ref = useRef(null as unknown as SVGSVGElement);
  const [groupedData, setGroupedData] = useState<AncestryRegion[]>([]);

  useEffect(() => {
    if (ancestryData && ancestryData.status === 'complete') {
      const groups: AncestryGroup[] = [];
      const regions = ancestryData.regions
        .filter(({ groups }) => groups.length > 0)
        .map((region: AncestryRegion) => {
          let percentage = 0;
          region.groups.map((item: AncestryGroup) => {
            percentage += item.percentage;
            groups.push(item);
          });
          return {
            ...region,
            percentage,
          };
        });
      drawPieChart(regions, 1, ref);
      drawPieChart(groups, 0, ref);
      setGroupedData(regions);
    }
  }, [ancestryData]);

  return groupedData ? (
    <Box css={classes.ancestry} data-testid="ancestry-graph">
      <svg width="100%" height="100%" viewBox="-140 -140 280 280" preserveAspectRatio="xMaxYMax meet" ref={ref} />
    </Box>
  ) : null;
}

export default AncestryGraph;
