import { Loader, useAuth } from '@omnigenbiodata/ui';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { PRIVACY_VERSION, TERMS_VERSION } from '../../../../core/constants/content.constants';
import ROUTES from '../../../../core/constants/routes.constants';
import SplashLayout from '../../../../layouts/SplashLayout';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  getEhrStatusThunk,
  isBusySelector as isEhrBusySelector,
  responseSelector as ehrResponseSelector,
} from '../../../../store/ehr';
import {
  getParticipantThunk,
  isBusySelector,
  hasSuccessSelector,
  hasCheckedSelector,
  hasWithdrawnFullySelector,
  responseSelector,
} from '../../../../store/participant';
import { getParticipantPreferencesThunk } from '../../../../store/preferences';
import { getAncestryThunk } from '../../../../store/ancestry';

function SplashScene() {
  const { signOut } = useAuth();
  const dispatch = useAppDispatch();
  const isBusy = useAppSelector(isBusySelector);
  const hasSuccess = useAppSelector(hasSuccessSelector);
  const hasChecked = useAppSelector(hasCheckedSelector);
  const hasWithdrawnFully = useAppSelector(hasWithdrawnFullySelector);
  const participant = useAppSelector(responseSelector);
  const isEhrBusy = useAppSelector(isEhrBusySelector);
  const ehrResponse = useAppSelector(ehrResponseSelector);

  useEffect(() => {
    dispatch(getParticipantThunk());
    dispatch(getParticipantPreferencesThunk());
    dispatch(getEhrStatusThunk());
    dispatch(getAncestryThunk());
  }, [dispatch]);

  useEffect(() => {
    if (!isBusy && hasWithdrawnFully) {
      signOut();
    }
  }, [hasWithdrawnFully, isBusy, signOut]);

  if (!isBusy && hasWithdrawnFully) {
    return null;
  }

  if (!isBusy && hasSuccess && participant && !isEhrBusy && ehrResponse) {
    if (participant.termsVersion === TERMS_VERSION && participant.privacyVersion === PRIVACY_VERSION) {
      return <Navigate to={ROUTES.portal} />;
    } else {
      return <Navigate to={ROUTES.policies} />;
    }
  }

  if (!isBusy && hasChecked && !hasSuccess) {
    return <Navigate to={ROUTES.enrolment} />;
  }

  return (
    <SplashLayout>
      <Loader isVisible label="Checking status" />
    </SplashLayout>
  );
}

export default SplashScene;
