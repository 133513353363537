import { createSlice } from '@reduxjs/toolkit';
import { EhrState } from './types';
import { StoreStatus } from '../../core/types/common.types';
import {
  getEhrStatusThunk,
  getImmunisationsThunk,
  getConditionsThunk,
  getMedicationsThunk,
  getAllergiesThunk,
} from './thunks';

// Initial State
export const initialState: EhrState = {
  errors: null,
  status: StoreStatus.IDLE,
  immunisations: {
    errors: null,
    response: null,
    status: StoreStatus.IDLE,
  },
  conditions: {
    errors: null,
    response: null,
    status: StoreStatus.IDLE,
  },
  medications: {
    errors: null,
    response: null,
    status: StoreStatus.IDLE,
  },
  allergies: {
    errors: null,
    response: null,
    status: StoreStatus.IDLE,
  },
  response: null,
};

// Slice
export const participantPreferencesSlice = createSlice({
  name: 'ehr',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getEhrStatusThunk.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
    });

    builder.addCase(getEhrStatusThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatus.IDLE;
      state.response = action.payload;
      state.errors = null;
    });

    builder.addCase(getEhrStatusThunk.rejected.type, (state, { payload }: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = payload;
      state.response = null;
    });

    builder.addCase(getImmunisationsThunk.pending.type, (state) => {
      state.immunisations.status = StoreStatus.BUSY;
    });

    builder.addCase(getImmunisationsThunk.fulfilled.type, (state, action: any) => {
      state.immunisations.status = StoreStatus.IDLE;
      state.immunisations.response = action.payload;
      state.immunisations.errors = null;
    });

    builder.addCase(getImmunisationsThunk.rejected.type, (state, { payload }: any) => {
      state.immunisations.status = StoreStatus.ERROR;
      state.immunisations.errors = payload;
      state.immunisations.response = null;
    });

    builder.addCase(getConditionsThunk.pending.type, (state) => {
      state.conditions.status = StoreStatus.BUSY;
    });

    builder.addCase(getConditionsThunk.fulfilled.type, (state, action: any) => {
      state.conditions.status = StoreStatus.IDLE;
      state.conditions.response = action.payload;
      state.conditions.errors = null;
    });

    builder.addCase(getConditionsThunk.rejected.type, (state, { payload }: any) => {
      state.conditions.status = StoreStatus.ERROR;
      state.conditions.errors = payload;
      state.conditions.response = null;
    });

    builder.addCase(getMedicationsThunk.pending.type, (state) => {
      state.medications.status = StoreStatus.BUSY;
    });

    builder.addCase(getMedicationsThunk.fulfilled.type, (state, action: any) => {
      state.medications.status = StoreStatus.IDLE;
      state.medications.response = action.payload;
      state.medications.errors = null;
    });

    builder.addCase(getMedicationsThunk.rejected.type, (state, { payload }: any) => {
      state.medications.status = StoreStatus.ERROR;
      state.medications.errors = payload;
      state.medications.response = null;
    });

    builder.addCase(getAllergiesThunk.pending.type, (state) => {
      state.allergies.status = StoreStatus.BUSY;
    });

    builder.addCase(getAllergiesThunk.fulfilled.type, (state, action: any) => {
      state.allergies.status = StoreStatus.IDLE;
      state.allergies.response = action.payload;
      state.allergies.errors = null;
    });

    builder.addCase(getAllergiesThunk.rejected.type, (state, { payload }: any) => {
      state.allergies.status = StoreStatus.ERROR;
      state.allergies.errors = payload;
      state.allergies.response = null;
    });
  },
});

// Action creators
export const { reset } = participantPreferencesSlice.actions;
export * from './thunks';
export * from './selectors';
export * from './types';
export default participantPreferencesSlice.reducer;
